import React from 'react'
// import {StaticImage} from 'gatsby-plugin-image'
import Img from 'gatsby-image'

import Cta from '../../components/cta'
import Modal from '../../components/modal'

const FreeProductOffer = ({
  image,
  dataTarget,
  modalId,
  formSrc,
  description,
  cta,
  ctaIsLink,
}) => {
  return (
    <div className="columns is-vcentered is-centered has-text-centered my-4">
      <div className="column is-two-thirds">
        <div>
          <h2 className="title is-3">
            {description ? (
              <div dangerouslySetInnerHTML={{__html: description}} />
            ) : (
              '¡Obtén tus primeras clases de francés de forma gratuita ahora mismo!'
            )}
          </h2>
          {/* <Cta
            text={cta || '¡Recibir lecciones de francés!'}
            dataTarget={dataTarget}
            isLink={ctaIsLink}
          /> */}
          <iframe
            width="540"
            height="250"
            src={formSrc}
            frameborder="0"
            scrolling="auto"
            allowfullscreen
            style={{
              Display: 'block',
              maxWidth: '100%',
              margin: 'auto',
              display: 'flex',
              borderRadius: 8,
              background: 'white',
            }}
          />
        </div>
      </div>
      {/* <div className="column">
        <Img fluid={image} alt="Free Product Offer" />
      </div> */}
      {/* <Modal id={modalId} src={formSrc} /> */}
    </div>
  )
}

export default FreeProductOffer
