import React from 'react'
import styled from 'styled-components'

import useModal from '../hooks/useModal'

const Modal = ({onClose, id, src}) => {
  useModal()

  return (
    <div id={id || 'modal-cta'} class="modal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <iframe
          width="540"
          height="300"
          src={
            src ||
            'https://e7b4fa4e.sibforms.com/serve/MUIFAB5mQpaseejIiL-Ush1rfIL5fBN2OP8_czlUSXhwkvsvo6KsoPxz2nvsJhGcpmgdhECwxTY_SKDMqsrgRaHIiN-fKp2C7KYBXIzmUe4WR6bKR8-tcAgLMUJnJO_BDLPABlGEKLIGfPC_KWViPoGwVPelyp7PAJVmFoX1x-lE-vFNPNkK5XO0kQIcoWfZtTXifXM39UKw3noA'
          }
          frameborder="0"
          scrolling="auto"
          allowfullscreen
          style={{
            Display: 'block',
            maxWidth: '100%',
            margin: 'auto',
            display: 'flex',
            borderRadius: 8,
            background: 'white',
          }}
        />
      </div>
      <button class="modal-close is-large" aria-label="close" />
    </div>
  )
}

export default Modal
