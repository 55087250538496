import React from 'react'
import {Link} from 'gatsby'

const buttonStyles = {
  backgroundColor: 'white',
  color: 'black',
  border: '2px solid black',
  borderRadius: '25px',
  padding: '10px 55px',
  boxShadow: '3px 4px 0px rgba(0, 0, 0, 1)',
  cursor: 'pointer',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  // fontWeight: 'bold',
  transition: 'transform 0.2s', // Add a transition for smooth scaling

  // Apply the scaling transformation on hover
  ':hover': {
    transform: 'scale(1.05)', // Scale the button to 105% of its original size
  },

  // Define media queries for responsive design
  '@media screen and (max-width: 768px)': {
    // fontSize: '14px',
    // padding: '8px 45px',

    fontSize: '13px',
    padding: '5px 10px',
  },
}

const Cta = ({text, dataTarget, isLink, link}) => {
  return isLink ? (
    <Link
      to={link || 'https://discord.gg/BqJxSvYepH'}
      className="scale-button shining-button"
      style={buttonStyles}
    >
      {text || 'Acceder al método'}
      <span className="shine"></span>
    </Link>
  ) : (
    <button
      data-target={dataTarget || 'modal-cta'}
      className="scale-button shining-button js-modal-trigger"
      style={buttonStyles}
    >
      {text || 'Acceder al método'}
      <span className="shine"></span>
    </button>
  )
}

export default Cta
