import React, {useEffect} from 'react'

function useModal() {
  useEffect(() => {
    // Functions to open and close a modal
    function openModal($el) {
      $el.classList.add('is-active')
    }

    function closeModal($el) {
      $el.classList.remove('is-active')
    }

    function closeAllModals() {
      const modals = document.querySelectorAll('.modal')
      modals.forEach($modal => {
        closeModal($modal)
      })
    }

    // Add a click event on buttons to open a specific modal
    const triggers = document.querySelectorAll('.js-modal-trigger')
    triggers.forEach($trigger => {
      const modal = $trigger.dataset.target
      const $target = document.getElementById(modal)

      $trigger.addEventListener('click', () => {
        openModal($target)
      })
    })

    // Add a click event on various child elements to close the parent modal
    const closeElements = document.querySelectorAll(
      '.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button',
    )
    closeElements.forEach($close => {
      const $target = $close.closest('.modal')

      $close.addEventListener('click', () => {
        closeModal($target)
      })
    })

    // Add a keyboard event to close all modals
    document.addEventListener('keydown', event => {
      if (event.code === 'Escape') {
        closeAllModals()
      }
    })
  }, []) // Empty dependency array to run the effect only once

  return null
}

export default useModal
