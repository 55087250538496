import * as React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import Modal from '../components/modal'
import SEO from '../components/seo'
import FreeProductOffer from '../components/FreeProductOffer'

import {Link} from 'gatsby'

// Helper function to normalize strings
const normalizeString = str =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

export default function BlogPostTemplate({data}) {
  const articles = data.allMarkdownRemark.edges
  const allVerbs = data.allVerbsJson.nodes
  const [searchTerm, setSearchTerm] = React.useState('')

  const sortedVerbsArray = allVerbs
    .slice()
    .sort((a, b) => a.infinitive.localeCompare(b.infinitive))

  // Filter verbs based on normalized input
  const filteredVerbs = sortedVerbsArray.filter(
    verb =>
      normalizeString(verb.infinitive).includes(normalizeString(searchTerm)) ||
      normalizeString(verb.slug).includes(normalizeString(searchTerm)),
  )

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return (
    <Layout>
      <SEO
        title="Aprende Francés: Los verbos"
        description="Descubre artículos informativos sobre la lengua y cultura francesa. Aprende francés con consejos y recursos útiles en el blog de Lemot."
        hasJsonLd
      />
      <div className="wrapper">
        <Modal />
        <section className="section is-medium">
          <h1 className="title has-text-centered">Busca un verbo en francés</h1>
          <input
            className="input is-large is-rounded"
            type="text"
            placeholder="Escribe aquí..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </section>
        <section className="section is-small">
          <div className="columns is-multiline ">
            {filteredVerbs.map(verb => (
              <div className="column is-one-quarter" key={verb.slug}>
                <Link to={`/verbos/${verb.slug}`} class="">
                  <div
                    style={{
                      minHeight: 150,
                      // background: 'linear-gradient(to top, #f0f0f0, #cccccc)',
                    }}
                    className="card has-background-light"
                  >
                    <div className="card-content">
                      <div className="has-text-weight-bold top-bottom-overflow-fade ">
                        <span>{capitalizeFirstLetter(verb.infinitive)}</span>
                        <span>{` (${capitalizeFirstLetter(verb.slug)})`}</span>
                      </div>
                      <div
                        style={{flexDirection: 'column'}}
                        className="content is-flex is-flex-direction-column transparent_gradient"
                      >
                        {verb.conjugations.slice(0, 3).map(tense => (
                          <span className="is-size-7">{tense.tense}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>
        <section className="section is-small">
          <FreeProductOffer
            description={`Recibe cada semana una colección de sugerencias de aprendizaje para seguir adelante en tu progreso.`}
            image={data.FreeProductImage.childImageSharp.fluid}
            dataTarget="FreeProductModalBlog"
            modalId="FreeProductModalBlog"
            formSrc="https://e7b4fa4e.sibforms.com/serve/MUIFAFh-RDlH4kZiUa4IpWxsDizFvims_CjQS5RNoAGLiPE8Fr4OSrX-sx8BdgW6BLTcZzdRuJCRARxXxgl-Q_dkIliCe6O53Hg5kGSxrG81-lgMKyV8lj_xB200-5jxYIvCK5hnxT0r6vi-bAOfHcAHWZ4l_KrT4f31lF1Cmd8IcK8s8-hEoJnFv5NPWIUEFwu8q1H49IeqpX3P"
          />
        </section>
        <div class="notification is-white  has-text-black has-text-centered">
          <strong>
            Cómo podemos mejorar esta página para ti, háznoslo saber usando el
            chat.
          </strong>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    images: allFile(
      filter: {relativeDirectory: {eq: "index"}}
      sort: {fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            metaTitle
            metaDescription
          }
        }
      }
    }
    allVerbsJson {
      nodes {
        infinitive
        slug
        conjugations {
          tense
        }
      }
    }
    FreeProductImage: file(name: {eq: "FreeProductImageVerb"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
